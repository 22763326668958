<template>
    <div class="user-menu">
        <a-dropdown :trigger="['hover']">
            <a-button @click.prevent type="primary">
                <template #icon>
                    <UserOutlined />
                </template>
                {{ userName }}
            </a-button>
            <template #overlay>
                <a-menu>
                    <a-menu-item key="0" @click="backHomePage">首页</a-menu-item>
                    <a-menu-divider />
                    <a-menu-item key="3" @click="logout">退出登录</a-menu-item>
                </a-menu>
            </template>
        </a-dropdown>
    </div>
</template>

<script lang="ts" setup>
    import { UserOutlined } from '@ant-design/icons-vue'
    import { useRouter } from 'vue-router'
    import { removeToken, removeUserInfo } from '~/utils/auth'
    import { logout as Logout } from '~/api/user'
    import { Modal } from 'ant-design-vue'
    import { getUserInfo } from '~/utils/auth'

    const router = useRouter()
    const userName = getUserInfo().username
    /**
     * @description 回到主页
     */
    function backHomePage() {
        router.replace('/home')
    }

    /**
     * @description 登出
     */
    function logout() {
        Modal.confirm({
            title: '提示?',
            content: '确认退出登录?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                Logout().then(() => {
                    removeToken()
                    removeUserInfo()
                    window.location.reload()
                })
            },
        })
    }
</script>

<style lang="scss" scoped>
    .user-menu {
        display: flex;
        align-items: center;
        > div {
            justify-content: space-evenly;
            display: flex;
            align-items: center;
            font-weight: 500 !important;
            div {
                margin-right: 20px;
            }
        }
    }
</style>
