import { computed } from 'vue'
import store from '~/store/index'
import { joinUrl } from '~/utils'
import type { IGameInfo } from '~/interfaces'

/**
 * @description 站点ID控制
 */

function useGameState() {
    const currentSiteId = computed<number>(() => store.getters.getCurrentSiteId)
    const siteList = computed<IGameInfo[]>(() => store.getters.getSiteList)
    const getGameInfo = computed<IGameInfo>(() => store.getters.getGameInfo)
    return {
        currentSiteId, // productID
        siteList,
        getGameInfo,
        /**
         * @description 设置站点ID
         */
        setSiteId: function (id: number) {
            store.commit('SET_CURRENTSITEID', id)
        },
        /**
         * @description 设置当前站点列表
         */
        setSiteIdList: function (list: IGameInfo[]) {
            list.forEach((item) => {
                item.gameIcon = joinUrl(item.gameIcon)
            })
            store.commit('SET_SITELIST', list)
        },
        /**
         * @description 设置当前游戏信息
         */
        setGameInfo: function (newGameInfo: IGameInfo) {
            newGameInfo.gameIcon = joinUrl(newGameInfo.gameIcon)
            store.commit('SET_CURRENTGAMEINFO', newGameInfo)
        },
    }
}

export { useGameState }
