<template>
    <a-sub-menu :key="menuInfo.path" v-bind="$attrs">
        <template #title>
            <span>
                <component :is="menuInfo.meta.icon" />
                <span>{{ menuInfo.meta && menuInfo.meta.title }}</span>
            </span>
        </template>
        <template v-for="item in menuInfo.children" :key="item.path">
            <template v-if="!item.children">
                <a-menu-item :key="item.path">
                    <router-link :to="item.path">
                        <component :is="item.meta.icon" />
                        <span>{{ item.meta.title }}</span>
                    </router-link>
                </a-menu-item>
            </template>
            <template v-else>
                <!-- <a-menu-item v-if="item.children.length === 1" :key="item.children[0].path">
                    <BorderlessTableOutlined />
                    <span>{{ item.children[0].meta && item.children[0].meta.title }}</span>
                </a-menu-item>
                <sub-menu v-else :menu-info="item" :key="item.path" /> -->
                <sub-menu :menu-info="item" :key="item.path" />
            </template>
        </template>
    </a-sub-menu>
</template>

<script lang="ts">
    import { defineComponent } from 'vue'
    import { BorderlessTableOutlined } from '@ant-design/icons-vue'
    export default defineComponent({
        name: 'SubMenu',
        components: {
            BorderlessTableOutlined,
        },
        props: {
            menuInfo: {
                type: Object,
                default: () => ({}),
            },
        },
        setup() {},
    })
</script>
