import { RouteRecordName } from 'vue-router'
import { Module } from 'vuex'
import type { IGameInfo } from '~/interfaces'

// 只写了主要的属性,后续增加可对照接口返回数据添加
export interface IHistoryTag {
    title: string
    path: string
    name: string
    keyPath: { name?: RouteRecordName }[]
    active?: boolean
}

interface Istate {
    siteList: IGameInfo[] // 站点列表
    currentSiteId: number // 当前站点ID
    currentGameInfo?: IGameInfo // 当前游戏基础信息
}

const Config: Module<Istate, unknown> = {
    state: () => ({
        siteList: [],
        currentSiteId: 0,
        currentGameInfo: {
            updateTime: '',
            createTime: '',
            isDelete: false,
            gameId: 0,
            gameName: '',
            gameIcon: '',
            onlineTime: '',
            onlineArea: '',
            productId: '',
            onlineTimeZone: '',
            productKey: '',
            callBackUrl: '',
            callBackKey: '',
            refundBackUrl: '',
            appSecret: '',
            onlineTimeZoneName: '',
        },
    }),
    mutations: {
        SET_SITELIST(state, list: IGameInfo[]) {
            state.siteList = list
        },
        SET_CURRENTSITEID(state, id: number) {
            state.currentSiteId = id
        },
        SET_CURRENTGAMEINFO(state, newData: IGameInfo) {
            state.currentGameInfo = newData
        },
    },
    getters: {
        getCurrentSiteId: (state) => state.currentSiteId,
        getSiteList: (state) => state.siteList,
        getGameInfo: (state) => state.currentGameInfo,
    },
}

export default Config
