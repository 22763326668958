import axios from 'axios'
import { getToken, removeToken, removeUserInfo } from '../auth'
const { VITE_APP_SDK_API } = import.meta.env
import { useGameState } from '~/hooks/setting'
import { message } from 'ant-design-vue'
interface IResponseDTO<T = any> {
    data: T
    code: number
    message: string
}

const http = axios.create({
    baseURL: VITE_APP_SDK_API?.toString(),
    withCredentials: false,
    timeout: 60000,
})

const { currentSiteId, getGameInfo } = useGameState()

// 请求拦截
http.interceptors.request.use(
    (config) => {
        const token = getToken()
        if (token) config.headers['Authorization'] = token
        if (currentSiteId.value) {
            config.headers['ProductId'] = currentSiteId.value
            config.headers['GameId'] = getGameInfo.value.gameId
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

http.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.message.includes('timeout')) {
            message.error('服务端响应超时')
            return Promise.reject(error)
        }
        switch (error.response.status || error) {
            case 401:
                message.error(error.toString())
                removeToken()
                removeUserInfo()
                location.reload()
                break
            default:
                message.error(error.toString())
                break
        }
        return Promise.reject(error)
    }
)

export { http }
export type { IResponseDTO }
