import { unref, FunctionalComponent, ref } from 'vue'
// import store from '~/store'
import { permissionsModules } from '~/route'
import { cloneDeep } from 'lodash'
import { RouteRecordRaw } from 'vue-router'

export type TAccessListItemRaw = {
    path: string
    component: string
    name?: string
    meta?: {
        title?: string
        hidden?: string
        icon?: string | FunctionalComponent
    }
    redirect?: string
    children?: TAccessListItemRaw[]
}

const menu = ref<TAccessListItemRaw[]>([])

export function useAccessList() {
    // const menu = computed(() => store.getters['User/accessList'])
    function setMenu(routes: TAccessListItemRaw[]) {
        const awaitFlatArr = cloneDeep(permissionsModules)
        const result: RouteRecordRaw[] = []
        // 拍平嵌套路由
        function flat(arr: RouteRecordRaw[]) {
            if (typeof routes[0].meta?.icon === 'function') return
            arr.forEach((item) => {
                result.push(item)
                if (item.children) {
                    flat(item.children)
                }
            })
            setMenuIcon(routes)
        }
        function setMenuIcon(route: TAccessListItemRaw[]) {
            unref(route).forEach((item) => {
                result.forEach((_item) => {
                    if (item.path === _item.path && item.meta) {
                        item.meta.icon = _item?.meta?.icon as FunctionalComponent
                    }
                    if (item.children) {
                        setMenuIcon(item.children)
                    }
                })
            })
        }
        flat(awaitFlatArr)
        // store.commit('User/SET_MENU', unref(routes))
        menu.value = unref(routes)
    }

    return {
        menu,
        setMenu,
    }
}
