<template>
    <a-config-provider :locale="zhCN">
        <router-view />
    </a-config-provider>
    <div
        class="water-mark"
        :style="{
            backgroundImage: `url(${waterMark(getUserInfo().username)})`,
        }"
    >
    </div>
</template>

<script lang="ts" setup>
    import zhCN from 'ant-design-vue/es/locale/zh_CN'
    import { provide } from 'vue'
    import { useGameState } from '~/hooks/setting'
    import { waterMark } from './utils/water-mark'
    import { getUserInfo } from './utils/auth'

    const { getGameInfo, currentSiteId } = useGameState()
    provide('gameInfo', getGameInfo ?? {})
    provide('currentSiteId', currentSiteId ?? 0)
</script>

<style lang="scss">
    body {
        margin: 0;
        padding: 0;
        min-width: 1200px;
        font-size: 12px;
    }
    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .water-mark {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1999;
        pointer-events: none;
        background-repeat: repeat;
        background-size: 300px 300px;
        opacity: 1;
    }
</style>
