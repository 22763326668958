import { RouteRecordRaw } from 'vue-router'
import { permissionsModules } from '~/route'

// 判断目标路由是否为外侧菜单路由
export function isHomePage(path: string) {
    let allowAccess = true
    function recursion(routes: RouteRecordRaw[], path: string) {
        routes.forEach((item) => {
            if (item.path === path) {
                allowAccess = false
            }
            if (item.children) {
                recursion(item.children, path)
            }
        })
    }
    recursion(permissionsModules, path)
    return allowAccess
}
