<template>
    <div class="switch-game">
        <a-button title="游戏切换" ghost size="small" @click="showPanel = true">
            <template #icon><SwapOutlined /></template>
            切换游戏
        </a-button>
    </div>

    <Teleport to="body">
        <Transition name="fade">
            <div class="game-panel" v-show="showPanel" @click.self="showPanel = false">
                <Transition name="slide-right">
                    <div class="game-panel-in" v-show="showPanel">
                        <a-list :loading="loading" size="small" :data-source="list">
                            <template #loadMore>
                                <div class="load-more">
                                    <a-spin v-if="loadingMore" />
                                    <a-button v-else @click="loadMore">加载更多</a-button>
                                </div>
                            </template>
                            <template #renderItem="{ item }">
                                <a-list-item
                                    class="list-item"
                                    @click="onSwitchGame(item.productId)"
                                >
                                    <a-avatar shape="square" :src="item.gameIcon">{{
                                        item.gameName
                                    }}</a-avatar>
                                    <span class="game-name" :title="item.gameName"
                                        >{{ item.gameId }}-{{ item.gameName }}</span
                                    >
                                </a-list-item>
                            </template>
                        </a-list>
                    </div>
                </Transition>
            </div>
        </Transition>
    </Teleport>
</template>

<script lang="ts">
    import { SwapOutlined } from '@ant-design/icons-vue'
    import { message } from 'ant-design-vue'
    import { defineComponent, reactive, toRefs } from 'vue'
    import { getGameList, externalGet } from '~/api/SDK'
    import { useGameState } from '~/hooks/setting'
    export default defineComponent({
        name: 'SwitchGame',
        components: {
            SwapOutlined,
        },
        setup() {
            let pageSize = 10
            let pageNum = 1
            const state = reactive({
                showPanel: false,
                loadingMore: false,
                list: [],
                loading: false,
            })

            const { setSiteIdList, setSiteId, setGameInfo } = useGameState()
            function loadMore() {
                state.loadingMore = true
                getGameList({
                    pageSize: pageSize,
                    pageNum: pageNum,
                })
                    .then((res) => {
                        state.loadingMore = false
                        if (res.data.code === 200) {
                            state.list = state.list.concat(res.data.data.list)
                            setSiteIdList(state.list)
                            if (res.data.data.list.length === 0) {
                                message.warn('没有更多内容~')
                            } else {
                                pageNum++
                            }
                        } else {
                            message.warn(res.data.message)
                        }
                    })
                    .catch((err) => {
                        state.loadingMore = false
                        message.warn(err.data.message)
                    })
            }

            async function onSwitchGame(id: number) {
                state.loading = true
                const { data } = await externalGet(id)
                if (data.code === 200) {
                    setGameInfo(data.data)
                    setSiteId(data.data.productId)
                } else {
                    message.error(data.message)
                }
                state.loading = false
                state.showPanel = false
            }

            loadMore()

            return {
                ...toRefs(state),
                loadMore,
                onSwitchGame,
            }
        },
    })
</script>

<style lang="scss" scoped>
    .game-panel {
        position: fixed;
        z-index: 10;
        @include wh(100vw, 100vh);
        top: 0;
        left: 0;
        background-color: rgba($color: #000000, $alpha: 0.5);
        &-in {
            position: absolute;
            @include wh(280px, 100vh);
            left: 0;
            top: 0;
            background-color: white;
            box-shadow: -1px 0px 8px rgb(63, 63, 63);
            overflow: auto;
            .game-name {
                text-overflow: ellipsis;
                overflow: hidden;
                display: inline-block;
                width: 80%;
            }
        }
        .load-more {
            text-align: center;
            margin-top: 12px;
            height: 32px;
            line-height: 32px;
            margin-bottom: 20px;
        }
        .list-item {
            cursor: pointer;
        }
    }
</style>
