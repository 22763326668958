function waterMark(text: string) {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = 300
    canvas.height = 300
    if (!ctx) {
        throw 'ctx is null'
    }
    ctx.textAlign = 'start'
    ctx.textBaseline = 'middle'
    ctx.font = '20px Microsoft Yahei'
    ctx.fillStyle = 'rgba(184, 184, 184, 0.3)'
    ctx.translate(150, 150)
    ctx.rotate((-20 * Math.PI) / 180)
    ctx.fillText(text, 0, 0)
    return canvas.toDataURL('image/png')
}

export { waterMark }
