import { createRouter, RouteRecordRaw, createWebHistory } from 'vue-router'
const { VITE_APP_BASE } = import.meta.env

import Layout from '~/layout/index.vue'
import HomeLayout from '~/layout/home.vue'
import { AppstoreOutlined, DashboardOutlined, ControlOutlined } from '@ant-design/icons-vue'
/**
 * @description 如果需要设置某路由在侧边栏中隐藏可在meta中添加hidden:true
 * 如果需要使用图标可在meta中添加icon: '导入的对应图标'
 */

// 需要控制权限的路由
export const permissionsModules: RouteRecordRaw[] = [
    {
        path: '/control-panel',
        component: Layout,
        redirect: '/control-panel/index',
        children: [
            {
                path: '/control-panel/index',
                component: () => import('~/views/control-panel/index.vue'),
                name: 'control-panel',
                meta: {
                    title: '控制台',
                    icon: ControlOutlined,
                },
            },
        ],
    },
    {
        path: '/goods',
        component: Layout,
        redirect: '/goods/index',
        children: [
            {
                path: '/goods/index',
                component: () => import('~/views/goods/index.vue'),
                name: 'Goods',
                meta: {
                    title: '商品管理',
                    icon: AppstoreOutlined,
                },
            },
        ],
    },
    /* {
        path: '/package_manager',
        component: Layout,
        redirect: '/package_manager/index',
        children: [
            {
                path: '/package_manager/index',
                component: () => import('~/views/package/index.vue'),
                name: 'packageManager',
                meta: {
                    title: '包管理',
                    icon: AppstoreOutlined,
                },
            },
        ],
    },
    {
        path: '/test',
        component: Layout,
        redirect: '/test/index',
        children: [
            {
                path: '/test/index',
                component: () => import('~/views/beta/index.vue'),
                name: 'TestManager',
                meta: {
                    title: '测试管理',
                    icon: AppstoreOutlined,
                },
            },
        ],
    }, */
    {
        path: '/sdk-issuing-ability',
        component: Layout,
        redirect: '/sdk-issuing-ability/index',
        meta: {
            title: 'SDK发行能力',
            icon: AppstoreOutlined,
        },
        children: [
            {
                path: '/sdk-issuing-ability/index',
                component: () => import('~/views/sdk-issuing-ability/index.vue'),
                name: 'sdk-issuing-ability',
                meta: {
                    title: 'SDK发行能力',
                    icon: AppstoreOutlined,
                },
            },
            {
                path: '/sdk-issuing-ability/login-config',
                component: () => import('~/views/sdk-issuing-ability/login-config/index.vue'),
                name: 'login-config',
                meta: {
                    hidden: true,
                    title: '登录开关',
                    icon: AppstoreOutlined,
                },
            },
            {
                path: '/sdk-issuing-ability/pay-config',
                component: () => import('~/views/sdk-issuing-ability/pay-config/index.vue'),
                name: 'pay-config',
                meta: {
                    hidden: true,
                    title: '支付开关',
                    icon: AppstoreOutlined,
                },
            },
            {
                path: '/sdk-issuing-ability/login-params',
                component: () => import('~/views/sdk-issuing-ability/login-params/index.vue'),
                name: 'login-params',
                meta: {
                    hidden: true,
                    title: '登录参数',
                    icon: AppstoreOutlined,
                },
            },
            {
                path: '/sdk-issuing-ability/pay-params',
                component: () => import('~/views/sdk-issuing-ability/pay-params/index.vue'),
                name: 'pay-params',
                meta: {
                    hidden: true,
                    title: '支付参数',
                    icon: AppstoreOutlined,
                },
            },
            {
                path: '/sdk-issuing-ability/anti-addiction',
                component: () => import('~/views/sdk-issuing-ability/anti-addiction/index.vue'),
                name: 'anti-addiction',
                meta: {
                    hidden: true,
                    title: '实名与防沉迷',
                    icon: AppstoreOutlined,
                },
                children: [
                    {
                        path: '/sdk-issuing-ability/anti-addiction/holidayConfig',
                        component: () =>
                            import(
                                '~/views/sdk-issuing-ability/anti-addiction/components/holiday-configuration/index.vue'
                            ),
                        name: 'HolidayConfig',
                        meta: {
                            hidden: true,
                            title: '法定假日配置',
                            icon: AppstoreOutlined,
                        },
                    },
                ],
            },
            {
                path: '/sdk-issuing-ability/channel-config',
                component: () => import('~/views/sdk-issuing-ability/channel-config/index.vue'),
                name: 'channel-config',
                meta: {
                    hidden: true,
                    title: '渠道封禁',
                    icon: AppstoreOutlined,
                },
            },
            {
                path: '/sdk-issuing-ability/agreement',
                component: () => import('~/views/sdk-issuing-ability/agreement/index.vue'),
                name: 'Agreement',
                meta: {
                    hidden: true,
                    title: '协议管理',
                    icon: AppstoreOutlined,
                },
            },
            {
                path: '/sdk-issuing-ability/login-white-list',
                component: () => import('~/views/sdk-issuing-ability/login-white-list/index.vue'),
                name: 'login-white-list',
                meta: {
                    hidden: true,
                    title: '登录白名单',
                    icon: AppstoreOutlined,
                },
            },
        ],
    },
]

// 主页路由(无权限控制)
export const defaultModules: RouteRecordRaw[] = [
    {
        path: '/login',
        component: () => import('~/views/login/index.vue'),
        meta: {
            hidden: true,
            title: 'login',
        },
    },
    {
        path: '/',
        component: HomeLayout,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'Dashboard',
                component: () => import('~/home/dashboard/index.vue'),
                meta: {
                    title: '首页',
                    icon: DashboardOutlined,
                },
            },
        ],
    },
    {
        path: '/gameManager',
        component: HomeLayout,
        redirect: '/gameManager/index',
        children: [
            {
                path: '/gameManager/index',
                name: 'GameManager',
                component: () => import('~/home/game-list/index.vue'),
                meta: {
                    title: '游戏管理',
                    icon: AppstoreOutlined,
                },
            },
        ],
    },
    {
        path: '/channel-list',
        component: HomeLayout,
        redirect: '/channel-list/index',
        meta: {
            title: '渠道管理',
            icon: AppstoreOutlined,
        },
        children: [
            {
                path: '/channel-list/index',
                name: 'channel-list',
                component: () => import('~/home/channel-list/index.vue'),
                meta: {
                    title: '联运渠道',
                    icon: AppstoreOutlined,
                },
            },
        ],
    },
    {
        path: '/customer-service',
        component: HomeLayout,
        redirect: '/customer-service/index',
        meta: {
            title: '用户服务',
            icon: AppstoreOutlined,
        },
        children: [
            {
                path: '/customer-service/index',
                name: 'customer-service',
                component: () => import('~/home/customer-service/index.vue'),
                meta: {
                    title: '用户服务',
                    icon: AppstoreOutlined,
                },
            },
            {
                path: '/customer-service/query',
                component: () => import('~/home/customer-service/query/index.vue'),
                name: 'customerServiceQuery',
                meta: {
                    hidden: true,
                    title: '用户查询',
                    icon: AppstoreOutlined,
                },
            },
            {
                path: '/customer-service/banned',
                component: () => import('~/home/customer-service/banned/index.vue'),
                name: 'customerServiceBanned',
                meta: {
                    hidden: true,
                    title: '封禁管理',
                    icon: AppstoreOutlined,
                },
            },
            {
                path: '/customer-service/account',
                component: () => import('~/home/customer-service/account/index.vue'),
                name: 'customerServiceAccount',
                meta: {
                    hidden: true,
                    title: '账号管理',
                    icon: AppstoreOutlined,
                },
            },
            {
                path: '/customer-service/order',
                component: () => import('~/home/customer-service/order/index.vue'),
                name: 'customerServiceOrder',
                meta: {
                    hidden: true,
                    title: '充值订单',
                    icon: AppstoreOutlined,
                },
            },
        ],
    },
    {
        path: '/data-report',
        component: HomeLayout,
        redirect: '/data-report/index',
        children: [
            {
                path: '/data-report/index',
                name: 'data-report',
                component: () => import('~/home/data-report/index.vue'),
                meta: {
                    title: '数据报表',
                    icon: AppstoreOutlined,
                },
            },
        ],
    },
    /* {
        path: '/system-settings',
        component: HomeLayout,
        redirect: '/system-settings/index',
        children: [
            {
                path: '/system-settings/index',
                name: 'system-settings',
                component: () => import('~/home/system-settings/index.vue'),
                meta: {
                    title: '系统设置',
                    icon: AppstoreOutlined,
                },
            },
        ],
    }, */
]

const router = createRouter({
    history: createWebHistory(VITE_APP_BASE as string),
    routes: [
        ...defaultModules,
        ...permissionsModules,
        {
            path: '/:catchAll(.*)',
            component: () => import('~/views/404/index.vue'),
            meta: {
                hidden: true,
                title: '404',
            },
        },
    ],
})

export default router
