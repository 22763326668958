const { VITE_APP_CENTER_URL, VITE_APP_BASE } = import.meta.env

function removeToken() {
    window.localStorage.removeItem('userToken')
}

function setToken(token: string) {
    window.localStorage.setItem('userToken', token)
}

function getToken() {
    return window.localStorage.getItem('userToken')
}

function removeUserInfo() {
    window.localStorage.removeItem('userInfo')
}

function setUserInfo(userInfo: string) {
    window.localStorage.setItem('userInfo', userInfo)
}

function getUserInfo() {
    if (window.localStorage.getItem('userInfo')) {
        return JSON.parse(window.localStorage.getItem('userInfo') || '')
    } else {
        return false
    }
}

function getTicket() {
    window.location.href = `${VITE_APP_CENTER_URL}/?sub_redirect=${encodeURIComponent(
        window.location.protocol + '//' + window.location.host + VITE_APP_BASE
    )}`
}

export { removeToken, setToken, getToken, removeUserInfo, setUserInfo, getUserInfo, getTicket }
