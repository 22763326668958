import { Module } from 'vuex'
import { loginByTicket } from '~/api/user'
import { setToken, setUserInfo } from '~/utils/auth'
import type { TAccessListItemRaw } from '~/hooks/core/useAccessList'

interface Istate {
    userName: string
    accessList: TAccessListItemRaw[]
}

const Config: Module<Istate, unknown> = {
    namespaced: true,
    state: () => ({
        userName: '',
        accessList: [],
    }),
    mutations: {
        SET_NAME: (state, name: string) => {
            state.userName = name
        },
        SET_MENU: (state, newList: TAccessListItemRaw[]) => {
            // state.accessList.push(...newList)
            state.accessList = newList
        },
    },
    actions: {
        login({ commit }, ticket) {
            return new Promise<void>((resolve, reject) => {
                loginByTicket({ ticket: ticket })
                    .then((res) => {
                        const { _data } = res.data
                        setToken(_data.accessToken)
                        setUserInfo(JSON.stringify(_data.data))
                        commit('SET_NAME', _data.data.username)
                        resolve()
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
    },
    getters: {
        username: (state) => state.userName,
        accessList: (state) => state.accessList,
    },
}

export default Config
