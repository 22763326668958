import { createStore } from 'vuex'
import AppConfig from './modules/app'
import User from './modules/user'
import createPersistedState from 'vuex-persistedstate'

const store = createStore({
    modules: {
        AppConfig,
        User,
    },
    plugins: [
        createPersistedState({
            key: 'issuance_management_platform',
            // storage: window.sessionStorage,
            storage: window.localStorage,
        }),
    ],
})

export default store
