<template>
    <section class="main-container">
        <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
            <h1 class="website-title">{{ collapsed ? '发' : '发行SDK管理平台' }}</h1>
            <a-menu
                :style="{ height: 'calc(100vh - 48px)' }"
                v-model:selectedKeys="selectedKeys"
                v-model:openKeys="openKeys"
                @click="switchRoute"
                theme="dark"
                :mode="collapsed ? 'vertical' : 'inline'"
            >
                <template v-for="item in menu" :key="item.path">
                    <template v-if="!item.children">
                        <a-menu-item :key="item.path">
                            <router-link :to="item.path">
                                <component :is="item?.meta?.icon" />
                                <span>{{ item.meta && item.meta.title }}</span>
                            </router-link>
                        </a-menu-item>
                    </template>
                    <template v-else>
                        <a-menu-item v-if="item.children.length === 1" :key="item.children[0].path">
                            <router-link :to="item.path">
                                <component :is="item.children[0]?.meta?.icon" />
                                <span>{{
                                    item.children[0].meta && item.children[0].meta.title
                                }}</span>
                            </router-link>
                        </a-menu-item>
                        <sub-menu v-else :menu-info="item" :key="item.path" />
                    </template>
                </template>
                <a-tag class="version"> {{ useVersion() }}</a-tag>
            </a-menu>
        </a-layout-sider>
        <a-layout :style="{ width: `calc(100vw - 260px)` }">
            <div class="breadcrumb-container">
                <div>
                    <a-button @click="toggleCollapsed">
                        <MenuUnfoldOutlined v-if="collapsed" />
                        <MenuFoldOutlined v-else />
                    </a-button>
                    <a-breadcrumb style="position: relative">
                        <a-breadcrumb-item
                            v-for="(item, index) in routeNameList"
                            :key="index"
                            :href="item.path"
                            @click="handleTapBreadcrumb(item)"
                        >
                            {{ item.title }}
                        </a-breadcrumb-item>
                    </a-breadcrumb>
                </div>
                <UserMenu />
            </div>
            <a-layout-content class="layout-content">
                <AppMain />
            </a-layout-content>
        </a-layout>
    </section>
</template>

<script lang="ts" setup>
    import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons-vue'
    import { ref, watchEffect } from 'vue'
    import { RouteLocationNormalizedLoaded, RouteRecordRaw, useRoute, useRouter } from 'vue-router'
    import { SubMenu, UserMenu, AppMain } from './components'
    import { cloneDeep } from 'lodash'
    import type { TAccessListItemRaw } from '~/hooks/core/useAccessList'
    import { defaultModules } from '~/route'
    import { useVersion } from '~/hooks/setting'

    interface IAntDesignVueClickMenuProps {
        domEvent: Event
        eventEvent: string
        eventKeyPath: string[]
        item: any
        key: string
        keyPath: string[]
    }

    const router = useRouter()
    const route = useRoute()

    const selectedKeys = ref<string[]>([route.path])
    const openKeys = ref<string[]>([])
    const routeNameList = ref<{ title: string; path: string }[]>([])
    const collapsed = ref(false)
    const menu = getMenuItem()
    function getMenuItem() {
        const routes = cloneDeep(defaultModules)
        function filterRoutes(routes: TAccessListItemRaw[]) {
            for (let index = routes.length - 1; index >= 0; index--) {
                if (routes[index].meta?.hidden) {
                    routes.splice(index, 1)
                }
                routes[index]?.children &&
                    filterRoutes(routes[index].children as TAccessListItemRaw[])
            }
        }
        filterRoutes(routes as unknown as TAccessListItemRaw[])
        return routes
    }
    /**
     * @description 展开侧边栏对应栏目
     */
    function setCurrentOpenKeys(routes: RouteRecordRaw[]) {
        routes.forEach((item) => {
            if (route.path.indexOf(item.path) !== -1) {
                openKeys.value.push(item.path)
            }
            if (item.children) {
                setCurrentOpenKeys(item.children)
            }
        })
    }

    /**
     * @description 设置面包屑导航栏
     */
    function setBreadcrumb() {
        routeNameList.value = []
        route.matched.map((item) =>
            routeNameList.value.push({
                title: item.meta.title as string,
                path: item.path,
            })
        )
    }

    /**
     * @description 打开或隐藏侧边栏
     */
    function toggleCollapsed() {
        collapsed.value = !collapsed.value
    }

    function switchRoute(val: IAntDesignVueClickMenuProps) {
        router.push(val.key)
    }

    /**
     * @description 点击面包屑导航条
     */
    function handleTapBreadcrumb({ path }: { path: string }) {
        router.push(path)
    }

    setBreadcrumb()
    setCurrentOpenKeys(menu)
    /**
     * @description 处理选中隐藏子路由时父级菜单高亮
     */
    function handelSelectSubPage(route: RouteLocationNormalizedLoaded) {
        let url: any[]
        if (route.matched[route.matched.length - 2].redirect !== undefined) {
            url = [route.matched[route.matched.length - 2].redirect]
        } else {
            url = [route.matched[route.matched.length - 2].path]
        }
        if (route?.meta?.hidden === true) {
            selectedKeys.value = url
        }
    }

    watchEffect(() => {
        setBreadcrumb()
        selectedKeys.value = [route.path]
        handelSelectSubPage(route)
    })
</script>

<style lang="scss" scoped>
    .main-container {
        display: flex;
        background-color: #eee;
        overflow: hidden;
        height: 100vh;
        > section {
            width: 100%;
            padding: 0 14px 14px 14px;
            .layout-content {
                width: 100%;
                background: #fff;
                height: calc(100vh - 106px);
                overflow: auto;
            }
        }
    }
    .version {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }
    .website-title {
        text-align: center;
        display: block;
        font-size: 18px;
        padding: 10px 0;
        margin: 0;
        // background: #204b74;
        color: white;
        overflow: hidden;
        white-space: nowrap;
    }

    .breadcrumb-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 52px;
        > div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            > button {
                margin-right: 10px;
            }
        }
    }
</style>
