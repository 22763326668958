import router from '~/route/index'
import { getToken } from '~/utils/auth'
import { useAccessList } from '~/hooks/core/useAccessList'
import type { TAccessListItemRaw } from '~/hooks/core/useAccessList'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { permissionsModules } from '~/route/index'
import { unref } from 'vue'
import { message } from 'ant-design-vue'
import { isHomePage } from '~/layout/index'
// const { MODE } = import.meta.env
const { menu, setMenu } = useAccessList()

// 开发环境直接读取原始routes
// if (MODE === 'development') {
setMenu(permissionsModules as unknown as TAccessListItemRaw[])
// }

// 判断是否有对应路由权限
function havePermissions(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) {
    if (isHomePage(to.path)) {
        next()
        return
    }
    let isAccess = false
    function recursion(baseMenu: TAccessListItemRaw[]) {
        if (baseMenu.length === 0) return
        baseMenu.forEach((item) => {
            if (to.path === item.path) {
                isAccess = true
            }
            if (item.children) {
                recursion(item.children)
            }
        })
    }
    recursion(unref(menu) as TAccessListItemRaw[])
    const unfindable = router.getRoutes().filter((item) => item.path === to.path)
    if (!isAccess) {
        if (unfindable.length === 0) {
            message.warn('未找到该页面')
        } else {
            message.warn('您没有对应页面权限')
        }
    }

    isAccess ? next() : next(from.path)
}

router.beforeEach(async (to, from, next) => {
    document.title = to?.meta?.title + '-聚合SDK管理系统'
    // 查看是否存在token'
    const hasToken = getToken()
    if (hasToken) {
        if (to.path === '/login') {
            next({ path: '/' })
        } else {
            havePermissions(to, from, next)
        }
    } else {
        if (to.path === '/login') {
            next()
        } else {
            next(to.query.ticket ? '/login?ticket=' + to.query.ticket : '/login')
        }
    }
})
