import { http, IResponseDTO } from '~/utils/request/gameWebsite'
const { VITE_APP_CENTER_API } = import.meta.env

// 通过ticket换取token
export const loginByTicket = (data: { ticket: string }) =>
    http.request<IResponseDTO>({
        baseURL: VITE_APP_CENTER_API + '/Auth/loginByTicket',
        method: 'post',
        data,
    })

// ~
export const logout = () =>
    http.request<IResponseDTO>({
        baseURL: VITE_APP_CENTER_API + '/Auth/logout',
        method: 'post',
    })
