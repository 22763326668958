import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import * as Auth from './auth'

/**
 * @description Blob转base64
 * @param img
 * @param callback
 */
export function getBase64(img: File, callback: (base64Url: string) => void) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result as string))
    reader.readAsDataURL(img)
}

/**
 * @param {number} len 随机字符串
 */
export function randomString(len: number) {
    len = len || 32
    const $chars =
        'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678' /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    const maxPos = $chars.length
    let pwd = ''
    for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return pwd
}

// 获取后缀名
export function getExtension(name: string) {
    return name.substring(name.lastIndexOf('.'))
}

// 校验手机号
export function testPhone(phone: string) {
    const phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/
    return phoneReg.test(phone)
}

/**
 * @description 拼接图标url
 */
export function joinUrl(url: string) {
    const REGMATCHPROTOCOL = /^(https|http)+/i
    if (!REGMATCHPROTOCOL.exec(url)) {
        url = import.meta.env.VITE_APP_SDK_API + url
    }
    return url
}

/**
 *
 * @param target 目标对象
 * @param key key
 * @param value value
 */
export const update = <T>(target: T, key: keyof T, value: T[keyof T]) => {
    target[key] = value
}

/**
 *
 * @param { string } text 所复制的文本内容
 * @param { function } callback 回调函数
 */
export const copyText = (text: string, callback?: () => void) => {
    const input = document.createElement('textarea')
    input.value = text
    input.style.position = 'absolute'
    input.style.opacity = '0'
    document.body.appendChild(input)
    input.select()
    input.setSelectionRange(0, text.length)
    const res = document.execCommand('copy')
    document.body.removeChild(input)
    if (!!res) {
        callback && callback()
    } else {
        message.warn('浏览器不支持访问剪贴板')
    }
}

export const dateRanges = {
    今天: [dayjs(new Date()).subtract(0, 'days'), dayjs(new Date()).subtract(0, 'days')],
    昨天: [dayjs(new Date()).subtract(1, 'days'), dayjs(new Date()).subtract(1, 'days')],
    近三天: [dayjs(new Date()).subtract(3, 'days'), dayjs(new Date()).subtract(0, 'days')],
    近七天: [dayjs(new Date()).subtract(7, 'days'), dayjs(new Date()).subtract(0, 'days')],
    本周: [dayjs().day(0), dayjs().day(6)],
    上个月: [
        dayjs(new Date()).subtract(1, 'months').startOf('month'),
        dayjs(new Date()).subtract(1, 'months').endOf('month'),
    ],
    近三个月: [dayjs(new Date()).subtract(3, 'months'), dayjs(new Date()).subtract(0, 'months')],
}

export function filterObj<T = any>(obj: any): T {
    if (typeof obj !== 'object') {
        return obj
    }
    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'undefined' || obj[key] === null || obj[key] === '') {
            delete obj[key]
        }
    })
    return obj
}
/**
 * @description 获取文件后缀名
 * @param name 文件名
 */
export const getSuffix = (name: string) => {
    return name?.slice((name.lastIndexOf('.') as number) + 1) // 获取文件后缀名
}

export { Auth }
