import { http, IResponseDTO } from '~/utils/request/sdk'
export * from './1-6-0'
export * from './1-7-0'
export * from './1-8-0'

interface IPagination {
    pageSize: number
    pageNum: number
}

// 接口文档 http://192.168.11.146:48321/shareDoc?salt=eb29ca9785c7d6a1#f0d83e33-4669-4b31-895f-1a2b4bcc01e1

/**
 * 获取游戏列表
 */
export const getGameList = (params: IPagination) =>
    http.request<IResponseDTO>({
        url: '/external/getList',
        method: 'get',
        params,
    })
/**
 * 上传图片
 */
export const uploadIcon = (data: FormData) =>
    http.request<IResponseDTO>({
        url: '/external/upload/iconUpload',
        method: 'POST',
        data,
    })
/**
 * 添加游戏
 */
export const externalAdd = (data: any) =>
    http.request<IResponseDTO>({
        url: '/external/add',
        method: 'post',
        data: data,
    })

/**
 * 获取指定游戏
 */
export const externalGet = (id?: number) =>
    http.request<IResponseDTO>({
        url: '/external/get?productId=' + id,
        method: 'GET',
    })

/**
 * 修改游戏参数
 */
export const externalUpdate = (data: any) =>
    http.request<IResponseDTO>({
        url: 'external/update',
        method: 'PUT',
        data,
    })

/**
 * ----- 对应游戏的接口
 */

/**
 * 查询渠道包列表 (v1.3有所更新,具体见453行)
 */
/* export const channelPageGetList = (params: IPagination) =>
    http.request<IResponseDTO>({
        url: 'channelPage/getList',
        method: 'GET',
        params,
    }) */

/**
 * 修改渠道包参数
 */
export const channelPageUpdate = (data: any) =>
    http.request<IResponseDTO>({
        url: 'channelPage/update',
        method: 'PUT',
        data,
    })

/**
 *
 * (v1.3废弃)
 * 查询渠道参数格式 http://192.168.11.146:23526/shareDoc?salt=0dd4232f5f3710eb#07e79a57-211d-4258-8e5c-f30d0fdcc610
 */
export const getFormatMap = () =>
    http.request<IResponseDTO>({
        url: 'channelConfig/getFormatMap',
        method: 'GET',
    })

/**
 * 插入渠道包
 */
export const channelPageAdd = (data: any) =>
    http.request<IResponseDTO>({
        url: 'channelPage/add',
        method: 'POST',
        data: data,
    })

/**
 * 查询渠道包渠道参数配置 (v1.3废弃)
 */
export const getChannelConfig = (packageId: number) =>
    http.request<IResponseDTO>({
        url: 'channelConfig/get',
        method: 'GET',
        params: {
            packageId,
        },
    })

/**
 * 获取所有商品档位
 */
export const getGoodsList = (params: IPagination) =>
    http.request<IResponseDTO>({
        url: 'goods/getList',
        method: 'GET',
        params,
    })

/**
 * 批量添加游戏商品档位
 */
export const goodsAddAll = (data: any) =>
    http.request<IResponseDTO>({
        url: 'goods/addAll',
        method: 'POST',
        data,
    })

/**
 * 查询指定商品档位
 */
export const goodsGet = (params: any) =>
    http.request<IResponseDTO>({
        url: 'goods/get',
        method: 'GET',
        params,
    })
/**
 * 查询指定商品档位
 */
export const goodsDel = (data: any) =>
    http.request<IResponseDTO>({
        url: 'goods/delete',
        method: 'POST',
        data: data,
    })

/**
 * 修改指定游戏产品(根据goodsID)
 */
export const goodsUpdate = (data: any) =>
    http.request<IResponseDTO>({
        url: 'goods/update',
        method: 'PUT',
        data,
    })
/**
 *
 * @param id
 * @returns
 */
export const goodsAddWithUpdateV2 = (formData: FormData) => {
    return http.request({
        url: '/goods/uploadIconAndUpdate',
        method: 'POST',
        data: formData,
    })
}
/**
 * 获取登录开关管理列表
 */
export const loginConfigGetList = (params: IPagination) =>
    http.request<IResponseDTO>({
        url: 'loginPay/getList/login',
        method: 'GET',
        params,
    })

/**
 * 配置登录开关
 */
export const updateLoginConfig = (data: any) =>
    http.request<IResponseDTO>({
        url: 'loginPay/update/login',
        method: 'PUT',
        data,
    })

/**
 * 获取支付开关管理列表
 */
export const getPayConfigList = (params: IPagination) =>
    http.request<IResponseDTO>({
        url: 'loginPay/getList/pay',
        method: 'GET',
        params,
    })

/**
 * 配置支付开关
 * */
export const updatePayConfig = (data: any) =>
    http.request<IResponseDTO>({
        url: 'loginPay/update/pay',
        method: 'PUT',
        data,
    })

/**
 * 获取登录参数列表
 */
export const getloginConfig = (params: IPagination) =>
    http.request<IResponseDTO>({
        url: 'sdk/loginConfig/getList',
        method: 'GET',
        params,
    })

/**
 * 插入登录参数
 */
export const loginConfigAdd = (data: any) =>
    http.request<IResponseDTO>({
        url: 'sdk/loginConfig/add',
        method: 'POST',
        data,
    })

/**
 * 更新登录参数
 */
export const loginConfigUpdate = (data: any) =>
    http.request<IResponseDTO>({
        url: 'sdk/loginConfig/update',
        method: 'PUT',
        data,
    })

/**
 * 获取单个登录参数
 */
export const loginConfigGet = (id: number) =>
    http.request<IResponseDTO>({
        url: 'sdk/loginConfig/get',
        method: 'GET',
        params: { id },
    })

/**
 * 查询支付参数列表
 */
export const payConfigGetList = (params: IPagination) =>
    http.request<IResponseDTO>({
        url: 'sdk/payConfig/getList',
        method: 'GET',
        params,
    })

/**
 * 插入支付参数(支付宝 | 微信)
 */
export const payConfigAdd = (data: any) =>
    http.request<IResponseDTO>({
        url: 'sdk/payConfig/add',
        method: 'POST',
        data,
    })
/**
 * 更新登录参数
 */
export const payConfigUpdate = (data: any) =>
    http.request<IResponseDTO>({
        url: 'sdk/payConfig/update',
        method: 'PUT',
        data,
    })

/***
 *  v1.2
 */

// 查询指定字典
export const getDictCotentList = (dictId: number) =>
    http.request<IResponseDTO>({
        url: '/dict/getDictCotentList',
        method: 'get',
        params: {
            dictId,
        },
    })

// 查询指定产品编辑记录
export const getEditLog = (gameId: number) =>
    http.request<IResponseDTO>({
        url: '/external/getEditLog',
        method: 'get',
        params: {
            gameId,
        },
    })

// 批量修改指定游戏产品
export const goodsUpdateAll = (data: {}[]) =>
    http.request<IResponseDTO>({
        url: '/goods/updateAll',
        method: 'put',
        data,
    })

/* 发行能力接口 */

// 查询实名与防沉迷配置
export const realAntiGetList = (params: IPagination) =>
    http.request<IResponseDTO>({
        url: '/realAnti/getList',
        method: 'GET',
        params,
    })

// 添加实名与防沉迷
export const realAntiAdd = (data: any) =>
    http.request<IResponseDTO>({
        url: '/realAnti/add',
        method: 'POST',
        data,
    })

// 修改实名与防沉迷
export const realAntiUpdate = (data: any) =>
    http.request<IResponseDTO>({
        url: '/realAnti/update',
        method: 'PUT',
        data,
    })

// 删除实名与防沉迷
export const realAntiDelete = (id: number) =>
    http.request<IResponseDTO>({
        url: '/realAnti/delete/' + id,
        method: 'DELETE',
    })

// 查询实名备案信息
export const realRecordGetList = (params: IPagination) =>
    http.request<IResponseDTO>({
        url: '/realRecord/getList',
        method: 'GET',
        params,
    })

// 添加实名备案信息
export const realRecordAdd = (data: any) =>
    http.request<IResponseDTO>({
        url: '/realRecord/add',
        method: 'POST',
        data,
    })

// 获取备案渠道列表
export const getRealrecordChannelListApi = (params?: any) =>
    http.request<IResponseDTO>({
        url: '/realRecord/channel/list',
        method: 'get',
        params,
    })

// 修改实名备案信息
export const realRecordUpdate = (data: any) =>
    http.request<IResponseDTO>({
        url: '/realRecord/update',
        method: 'PUT',
        data,
    })

// 删除实名备案信息
export const realRecordDelete = (id: number) =>
    http.request<IResponseDTO>({
        url: '/realRecord/delete/' + id,
        method: 'DELETE',
    })

// 查询查询法定假日
export const holidayConfigGetList = (params: IPagination) =>
    http.request<IResponseDTO>({
        url: '/holidayConfig/getList',
        method: 'GET',
        params,
    })

// 添加查询法定假日
export const holidayConfigAdd = (data: any) =>
    http.request<IResponseDTO>({
        url: '/holidayConfig/add',
        method: 'POST',
        data,
    })

// 修改查询法定假日
export const holidayConfigUpdate = (data: any) =>
    http.request<IResponseDTO>({
        url: '/holidayConfig/update',
        method: 'PUT',
        data,
    })

// 删除查询法定假日
export const holidayConfigDelete = (id: number) =>
    http.request<IResponseDTO>({
        url: '/holidayConfig/delete/' + id,
        method: 'DELETE',
    })

/**
 * v1.3文档地址
 * https://docs.apipost.cn/preview/15817a0d6c7ceeb4/cae8d77b895197d0?target_id=9dfe1857-e2e5-4b9a-b0f6-f47eb049bf14#f5e0c50c-7e14-4945-8298-ec88eedaf1d9
 */

// 添加或修改渠道
export const channel = (data: any) =>
    http.request({
        url: '/channel',
        method: 'put',
        data,
    })
// 添加或修改渠道
export const updateChannel = (data: any) =>
    http.request({
        url: '/channel',
        method: 'post',
        data,
    })

// 获取渠道列表
export const channelsList = (params: any) =>
    http.request({
        url: '/channels/page',
        method: 'GET',
        params: params,
    })

// 刷新SDK版本
export const refreshSDKversion = () =>
    http.request({
        url: '/channel/sdk-version/refresh',
        method: 'GET',
    })

// 删除渠道
export const delChannel = (id: number) =>
    http.request({
        url: '/channel/' + id,
        method: 'DELETE',
    })

// 禁用渠道
export const disableChannel = (id: number) =>
    http.request({
        url: '/channel/disable/' + id,
        method: 'POST',
    })

// 启用渠道
export const ableChannel = (id: number) =>
    http.request({
        url: '/channel/able/' + id,
        method: 'POST',
    })

// 按照发行地区分组获取所有渠道简单信息 (取代原接口channelPageGetList中返回的渠道信息)
export const channelsGrouping = () =>
    http.request({
        url: 'channels/grouping/online-area',
        method: 'get',
    })

// 渠道包列表(分页)
export const channelPageGetList = (params: IPagination) =>
    http.request({
        url: 'channelPage/getList',
        method: 'get',
        params,
    })

/**
 * v1.4文档地址
 * http://10.0.40.130:12744/shareDoc?salt=cae8d77b895197d0#e1d2f16c-1927-4321-bdd0-1cd4438d15e2
 */

// 支付订单
export const orderQuery = (params: IPagination) =>
    http.request({
        url: 'order/query',
        method: 'get',
        params: params,
    })

// 游戏列表（简单版，只显示游戏名和ID）
export const externalSimpleList = () =>
    http.request({
        url: 'external/simple/list',
        method: 'get',
    })

// 渠道列表（简单版，只显示游戏名和ID）
export const channelPageSimpleList = (productId: number) =>
    http.request({
        url: 'channelPage/simple/list',
        method: 'get',
        params: { productId },
    })

// 渠道列表（简单版，只显示游戏名和ID）查询全部
export const channelsSimpleList = () =>
    http.request({
        url: 'channels/simple/list',
        method: 'get',
    })

// 用户服务-用户查询接口
export const userinfoQuery = (params: any) =>
    http.request({
        url: 'userinfo/query',
        method: 'get',
        params: params,
    })

// 补单接口
export const orderRedelivery = (ordNum: string) =>
    http.request({
        url: 'order/redelivery',
        method: 'get',
        params: {
            ordNum,
        },
    })

// 强制补单接口
export const forceOrderRedelivery = (ordNum: string) =>
    http.request({
        url: '/order/redelivery/force',
        method: 'get',
        params: {
            ordNum,
        },
    })

/**
 * v1.4.1文档地址
 * http://10.0.40.130:12744/shareDoc?salt=cae8d77b895197d0
 */

// 新游戏列表
export const externalRemoteList = () =>
    http.request({
        url: 'external/remote/list',
    })

// 删除游戏
export const deleteGame = (id?: number) =>
    http.request({
        url: 'external/delete/' + id,
        method: 'DELETE',
    })
