import axios, { AxiosResponse } from 'axios'
import { getToken, removeToken, removeUserInfo } from '../auth'
import { useGameState } from '~/hooks/setting'
interface IResponseDTO<T = any> {
    _data: T
    _code: number
    _message: string
}

const http = axios.create({
    baseURL: '/',
    withCredentials: false,
    timeout: 12000,
})

const { currentSiteId } = useGameState()

// 请求拦截
http.interceptors.request.use(
    (config) => {
        const token = getToken()
        // token 设置
        if (token) config.headers['Authorization'] = token
        // 站点标识设置
        // if (currentSiteId) config.headers['Site-Id'] = currentSiteId.value
        if (currentSiteId) config.headers['Site-Id'] = '1'

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

http.interceptors.response.use(
    (response: AxiosResponse<IResponseDTO>) => {
        return response
    },
    (error) => {
        switch (error.response.data._code) {
            case 401:
                alert(error.response.data._message)
                removeToken()
                removeUserInfo()
                location.reload()
                break
            default:
                alert(error.response.data._message)
                break
        }
        return Promise.reject(error)
    }
)

export { http, IResponseDTO }
